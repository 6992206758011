import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TrackingLoginRegister } from 'src/app/models/tracking.model';
import { User } from 'src/app/models/user.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PlatformService } from 'src/app/services/plateform.service';
import { SeoService } from 'src/app/services/seo.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { signUp } from 'src/app/store/auth/auth.actions';
import { selectErrorAuth } from 'src/app/store/auth/auth.selectors';

@Component({
  selector: 'app-register-section',
  templateUrl: 'register-section.component.html',
})
export class RegisterSectionComponent implements OnInit {
  focus;
  focus1;
  focus2;
  focus3;
  signUpGroup: UntypedFormGroup;
  error$: Observable<any>;
  showPassword: boolean = false;
  language :string;
  constructor(
    private fb: UntypedFormBuilder, 
    private store: Store, 
    private router: Router,
    private trackingService: TrackingService,
    private platformService: PlatformService,  
    private localStorageService: LocalStorageService) {}

  ngOnInit(): void {      
    this.signUpGroup = this.fb.group({
      lastname: ['', Validators.required],
      firstname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      agreePolicy: [false, Validators.requiredTrue],
      identityProvider:['PIROG']
    });

    this.error$ = this.store.select(selectErrorAuth);
  }

  submitPirogProvider() {
    if (this.signUpGroup.valid && this.platformService.isPlatformBrowser()) {
      let user = this.signUpGroup.value as User;
      this.language = this.localStorageService.getItem("selectedLanguage") || navigator.language.split('-')[0];
      user.language = this.language;
      this.trackRegister();
      this.store.dispatch(signUp({ user }));
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  get lastnameCtrl(): UntypedFormControl {
    return this.signUpGroup.get('lastname') as UntypedFormControl;
  }

  get firstnameCtrl(): UntypedFormControl {
    return this.signUpGroup.get('firstname') as UntypedFormControl;
  }

  get emailCtrl(): UntypedFormControl {
    return this.signUpGroup.get('email') as UntypedFormControl;
  }

  get passwordCtrl(): UntypedFormControl {
    return this.signUpGroup.get('password') as UntypedFormControl;
  }

  get agreePolicyCtrl(): UntypedFormControl {
    return this.signUpGroup.get('agreePolicy') as UntypedFormControl;
  }

  trackRegister() {
    let trackingDTO: TrackingLoginRegister = {
      eventType: 'register',
      pageUrl: this.router.url
    }
    this.trackingService.track(trackingDTO);
  }
}
