import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { SeoService } from 'src/app/services/seo.service';
import { resetPasswordAttempt } from 'src/app/store/auth/auth.actions';
import { selectErrorMessage } from 'src/app/store/auth/auth.selectors';

@Component({
  selector: 'app-reset-password-attempt',
  templateUrl: './reset-password-attempt.component.html',
  styleUrls: ['./reset-password-attempt.component.scss']
})
export class ResetPasswordAttemptComponent implements OnInit, OnDestroy {
  
  resetPasswordAttemptForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  subscription: Subscription = new Subscription();
  error$: Observable<string>;

  constructor(
    private store: Store,
    private seoService: SeoService
  ) { }

  ngOnInit(): void {
    this.seoService.setSeoTags({
      titleKey: "seo.password_reset.title", 
      descriptionKey: "seo.password_reset.description",
      imageKey: "seo.password_reset_process.image",
      noindex: true
    });

    this.error$ = this.store.select(selectErrorMessage);
  }

  onSubmit(): void {
    if (this.resetPasswordAttemptForm.invalid) {
      this.resetPasswordAttemptForm.markAllAsTouched(); 
      return;
    }

    const { email } = this.resetPasswordAttemptForm.value;

    this.store.dispatch(
      resetPasswordAttempt({
        resetPasswordAttemptRequest: { email }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
