import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, switchMap } from 'rxjs';
import { ApplicationCreationRequest } from 'src/app/models/application-creation-request.model';
import { ApplicationCreationResponse } from 'src/app/models/application-creation-response.model';
import { DomainType } from 'src/app/models/domain-type.model';
import { Template } from 'src/app/models/template.model';
import { User } from 'src/app/models/user.model';
import { ApplicationService } from 'src/app/services/application.service';
import { SeoService } from 'src/app/services/seo.service';
import { loadUserById, verifyAccountAttempt } from 'src/app/store/auth/auth.actions';
import { selectHasAcceptedOurConditionsAndPrivacyPolicy, selectIsAuth, selectUser } from "src/app/store/auth/auth.selectors";
import { AppCreationOrder } from 'src/app/store/order/models/creation-order.model';
import { clearCreationOrder, setCreationOrder, setTemplateType } from 'src/app/store/order/order.actions';
import { selectCreationOrder } from 'src/app/store/order/order.selectors';
import { selectTemplates } from 'src/app/store/repository/repository.selector';
import { Location } from '@angular/common';
import { TrackingService } from 'src/app/services/tracking.service';
import { TrackingApplicationCreation } from 'src/app/models/tracking.model';
import { Application } from 'src/app/models/application.model';

@Component({
  selector: 'app-create-app-form',
  templateUrl: './create-app-form.component.html',
  styleUrls: ['./create-app-form.component.scss']
})
export class CreateAppFormComponent implements OnInit {
  template$: Observable<Template>;
  policyAccepted$: Observable<boolean>;
  isAuth$: Observable<boolean>;
  templateName: string;
  selectedTemplateName: string;

  user: User;
  applicationFg: FormGroup;
  isDomainAvailable = true;
  domainPattern = '([\\da-z-]+)';
  domainTypes: DomainType[] = [];
  template: Template;
  templateType: string;
  subscriptions: Subscription = new Subscription();
  isAuthenticated: boolean = false;
  templates$: Observable<Template[]>;

  constructor(
    private store: Store,
    private router: Router,
    private fb: FormBuilder,
    private applicationService: ApplicationService,
    private seoService: SeoService,
    private route: ActivatedRoute,
    private trackingService: TrackingService,
    private location: Location ) {}

    ngOnInit(): void {
      this.seoService.setSeoTags({
        titleKey:"seo.app_creation.title", 
        descriptionKey: "seo.app_creation.description", 
        imageKey: "seo.app_creation.image",
        noindex: true});
      this.templates$ = this.store.select(selectTemplates);
      this.store.dispatch(loadUserById());
      const _isAuth = this.store.select(selectIsAuth).subscribe(isAuthenticated => {
        this.isAuthenticated = isAuthenticated;
      });
      this.subscriptions.add(_isAuth);
    
      this.route.queryParams.subscribe(params => {
        this.templateName = params['name'] ;

        this.selectedTemplateName=this.templateName;
        this.preselectTemplate();
        this.location.replaceState(this.router.url.split('?')[0]);

      });
      
      this.applicationFg.get('templateType').valueChanges.subscribe((templateId) => {
        this.updateTemplateName(templateId);
      });
      this._user();
    }
    updateTemplateName(templateId: string) {
      this.templates$.pipe(
        switchMap(templates => {
          const selectedTemplate = templates.find(template => template.id === templateId);
          if (selectedTemplate) {
            this.selectedTemplateName = selectedTemplate.name;
          } else {
            this.selectedTemplateName = '';  
          }
          return [];  
        })
      ).subscribe();
    }
  
  // private initData() {
  //   this.template$ = this.store.select(selectTemplateByType(this.templateType));
  //   this._user();
  //   this._template();
  // }

  // private _template() {
  //   const _template = this.store.select(selectTemplateByType(this.templateType)).subscribe((template) => {
  //     this.template = template;
  //   });
  //   this.subscriptions.add(_template);
  // }

  onTemplateTypeChange(event: any): void {
    const templateId = event.target.value;
    this.updateTemplateName(templateId);
  }
  private _creationOrder() {
    const _creationOrder = this.store.select(selectCreationOrder).subscribe((creationOrder) => {
      if(creationOrder != null) {
        this.applicationFg.patchValue({
          appName: creationOrder.appName,
          domainName: creationOrder.subDomainName
        });
      }
    });
    this.subscriptions.add(_creationOrder);
  }

  private _user() {
    const _user = this.store.select(selectUser).subscribe((user) => {
      if(user){
        this.user = user;
      }
    });
    this.subscriptions.add(_user);
  }

  private preselectTemplate() {
    this.templates$.subscribe(templates => {
      const matchingTemplate = templates.find(template => template.name === this.templateName);
      if (matchingTemplate) {
        this.templateType = matchingTemplate.id;
      } else {
        this.templateType = '';  // Réinitialiser si aucun modèle n'est trouvé
      }
      this.initForm(this.templateType);
    });
  }
  
  private initForm(templateType: string): void {
    this.policyAccepted$ = this.store.select(selectHasAcceptedOurConditionsAndPrivacyPolicy);
    this.policyAccepted$.subscribe((hasPolicyAccepted) => {
      this.applicationFg = this.createFormBuilder(hasPolicyAccepted, templateType);
      this._creationOrder();
    });
  }

  createFormBuilder(hasPolicyAccepted: boolean,templateType: string) {
    let acceptTerms = hasPolicyAccepted ? [true] : [false, Validators.requiredTrue];

    return this.fb.group({
      templateType: [templateType, Validators.required],  // Set the templateType here
      appName: ["", Validators.required],
      offer: ['SUBDOMAIN', Validators.required], // TODO : remettre '' en valeur par default lorsqu'on aura d'autre offre de domaine
      domainName: ['', Validators.required],
      acceptTerms: acceptTerms
    });
  }

  redirectToTemplate() {
    this.router.navigate(['/store', this.templateType]);
  }

  public endTemplateName(string) {
    return string.substring(2, string.length);
  }

  submit() {
    if (!this.isFormValid()) return;

    if (this.isUserVerificationRequired()) {
      this.verifyAccountAttempt();
      return;
    }

    if (!this.isAuthenticated) {
      this.handleUnauthenticatedUser();
      return;
    }

    this.createApplicationRequest();
  }

  private isFormValid(): boolean {
      return this.applicationFg.valid;
  }

  private isUserVerificationRequired(): boolean {
      return this.user?.id != null && !this.user?.enabled;
  }

  private handleUnauthenticatedUser(): void {
      const creationOrder: AppCreationOrder = this.getCreationOrder();
      this.store.dispatch(setCreationOrder({ creationOrder }));
      this.router.navigate(['/login']);
  }

  private getCreationOrder(): AppCreationOrder {
      return {
          appName: this.appNameCtrl.value,
          idTemplate: this.templateTypeCtrl.value,
          subDomainName: this.domainNameCtrl.value,
          domainType: this.offerCtrl.value,
      };
  }

  private createApplicationRequest(): void {
      const request: ApplicationCreationRequest = this.getApplicationRequest();

      this.applicationService.createApplication(request)
        .subscribe({
          next: (uploadResult) => this.handleSuccess(uploadResult),
          error: (err: any) => this.handleError(err),
        });
  }

  private getApplicationRequest(): ApplicationCreationRequest {
      return {
          appName: this.appNameCtrl.value,
          idTemplate: this.templateTypeCtrl.value,
          subDomainName: this.domainNameCtrl.value,
          domainType: this.offerCtrl.value,
      };
  }

  private handleSuccess(uploadResult: ApplicationCreationResponse) {
    this.store.dispatch(setTemplateType({ templateType: null }));
    this.store.dispatch(clearCreationOrder());
    
    this.trackApplicationCreation(uploadResult.application);

    if (uploadResult.redirectToWaitingRoom) {
      this.router.navigate(['/app-loading', uploadResult.application.id]);
    } else {
      this.router.navigate(['/dashboard', 'applications', uploadResult.application.id, 'overview']);
    }
    
  }

  private handleError(error: any) {
    if (error.error && error.error.error === "domain_name_unavailable") {
        this.isDomainAvailable = false;
    }
  }

  verifyAccountAttempt() {
    this.store.dispatch(verifyAccountAttempt({ idUser: this.user.id }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get templateTypeCtrl(): FormControl {
    return this.applicationFg.get('templateType') as FormControl;
  }

  get appNameCtrl(): FormControl {
    return this.applicationFg.get('appName') as FormControl;
  }

  get offerCtrl(): FormControl {
    return this.applicationFg.get('offer') as FormControl;
  }

  get domainNameCtrl(): FormControl {
    return this.applicationFg.get('domainName') as FormControl;
  }

  get acceptTermsCtrl(): FormControl {
    return this.applicationFg.get('acceptTerms') as FormControl;
  }

  getFormControl(controlName: string): FormControl {
    return this.applicationFg.get(controlName) as FormControl;
  }

  trackApplicationCreation(application: Application) {
    let trackingDTO: TrackingApplicationCreation = {
      eventType: 'applicationCreated',
      pageUrl: this.router.url,
      templateId: application.template.id,
      templateName: application.template.name,
      applicationId: application.id,
      domainName: application.domain.name,
      subscriptionId: application.subscriptionId,
      subscriptionType: this.user.subscriptionType
    }
    this.trackingService.track(trackingDTO);
  }
}
