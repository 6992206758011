<section class="p-4">
  <div class="container d-flex justify-content-center">
    <div class="row d-flex align-items-center justify-content-center gradient-bg gradient-round">
      <div class="d-flex flex-column justify-content-center bg-white gradient-round p-4">
        <form role="form" (ngSubmit)="submitPirogProvider()">
          <div class="form-group">
            <div class="input-group input-group-alternative mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="ni ni-hat-3"></i
                ></span>
              </div>
              <input
                class="form-control"
                placeholder="{{ 'register.input_0' | translate }}"
                type="text"
                [formControl]="firstnameCtrl"
                aria-label="Firstname"
                name="firstname"
                autocomplete="on"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="input-group input-group-alternative mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="ni ni-hat-3"></i
                ></span>
              </div>
              <input
                class="form-control"
                placeholder="{{ 'register.input_1' | translate }}"
                type="text"
                [formControl]="lastnameCtrl"
                aria-label="lastname"
                autocomplete="on"
                name="Lastname"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="input-group input-group-alternative mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="ni ni-email-83"></i
                ></span>
              </div>
              <input
                class="form-control is-invalid"
                placeholder="Email"
                type="email"
                [formControl]="emailCtrl"
                aria-label="Email"
                name="email"
                autocomplete="on"
              />
              <div class="invalid-feedback" *ngIf="(error$ | async)?.status === 409">
                {{ 'register.find' | translate }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="ni ni-lock-circle-open"></i
                ></span>
              </div>
              <input
                class="form-control"
                placeholder="{{ 'register.input_3' | translate }}"
                [type]="showPassword ? 'text' : 'password'"
                [formControl]="passwordCtrl"
                aria-label="Password"
                name="password"
                autocomplete="on"
              />
              <div class="input-group-append">
                <button class="btn btn-outline-primary" type="button" (click)="toggleShowPassword()"><i *ngIf="!showPassword" class="fa fa-eye"></i><i *ngIf="showPassword" class="fa fa-eye-slash"></i></button>
              </div>
            </div>
          </div>
          <!--<div class="text-muted font-italic">
            <small
              >password strength:
              <span class="text-success font-weight-700">strong</span>
            </small>
          </div>-->
          <div class="row my-4">
            <div class="col-12">
              <div
                class="custom-control custom-control-alternative custom-checkbox"
              >
                <input
                  class="custom-control-input"
                  id="customCheckRegister"
                  type="checkbox"
                  [formControl]="agreePolicyCtrl"
                  aria-label="agreePolicy"
                  name="agreePolicy"
                  autocomplete="on"
                />
                <label
                  class="custom-control-label"
                  for="customCheckRegister"
                >
                  <span
                    >{{ 'register.span' | translate }}
                    <a [routerLink]="['/privacy']" target="_blank"><strong>{{ 'register.a0' | translate }} </strong></a>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div>
            <button type="submit" class="btn btn-dark mt-4">
              {{ 'register.button.2' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
