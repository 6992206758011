<!--<div class="page bg-white d-flex align-items-center">

</div>-->

<div class="container-fluid bg-primary-light-3">
    <div class="container whole-page d-flex align-items-center">
        <div class="card mt-5">
            <div class="card-body p-md-5">
                <div class="row justify-content-center">
                    <div class="col-md-6 p-md-5 m-auto">
                        <p *ngIf="selectedTemplateName"class="text-center p-lg mt--3">
                            <img src="assets/templates/{{selectedTemplateName}}/text_color.png" width="100px" alt="">
                        </p>
                        <form [formGroup]="applicationFg" (ngSubmit)="submit()">
                            <!-- <div class="card-title">
                                <h3 class="mb-0">{{ 'create_app_form.question' | translate }} <app-micro-app [isPlural]="false"></app-micro-app> ?</h3>
                            </div> -->

                            <div class="row">
                                <div class="col-md-12">

                                    <div class="form-group">
                                        <select
                                        data-toggle="select"
                                        class="form-control"
                                        id="templateType"
                                        name="templateType"
                                        [formControl]="getFormControl('templateType')"
                                        (change)="onTemplateTypeChange($event)">
                                      <option  value=""> -- select a product -- </option>
                                      <option *ngFor="let template of templates$ | async" [value]="template.id">
                                        {{ template.name }}
                                      </option>
                                    </select>


                                        <br>

                                        <label class="form-control-label" for="appName">
                                            {{ 'create_app_form.app_name' | translate }}
                                        </label>
                                        <input class="form-control"
                                            placeholder="{{ 'create_app_form.app_name_hint' | translate }}" type="text"
                                            id="appName" [formControl]="getFormControl('appName')" name="appName" />
                                    </div>

                                    <div class="form-group">
                                        <label class="form-control-label" for="domainName">
                                            {{'create_app_form.domain_name'|translate}}
                                        </label>
                                        <div class="input-group input-group-merge">
                                            <input
                                                class="form-control"
                                                placeholder="exemple"
                                                pattern="{{domainPattern}}"
                                                type="text"
                                                id="domainName"
                                                [formControl]="getFormControl('domainName')"
                                                name="domainName"
                                                oninput="this.value = this.value.toLowerCase()"/>
                                            <div class="input-group-append">
                                                <span class="input-group-text bg-gray text-white"> .pirog.io </span>
                                                <span class="input-group-text bg-gray">
                                                    <i *ngIf="isDomainAvailable" class="ni ni-lg ni-check-bold"
                                                        style="color:green"> </i>
                                                    <i *ngIf="!isDomainAvailable" class="ni ni-lg ni-fat-remove p-lg"
                                                        style="color:red"> </i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!(policyAccepted$ | async)" class="custom-control custom-checkbox mb-3">
                                        <input class="custom-control-input" id="acceptTerms" type="checkbox"
                                            name="acceptTerms" [formControl]="getFormControl('acceptTerms')" />
                                        <label class="custom-control-label" for="acceptTerms">
                                            {{ 'create_app_form.accept_terms' | translate }}
                                            <a class="text-primary" href="../../../../../../assets/pdf/CGU.pdf" download>
                                                <b>{{ 'create_app_form.terms' | translate }}</b>
                                            </a>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3 float-right">
                                <button (click)="redirectToTemplate()" type="button" class="btn btn-light  my-2">
                                    {{ 'cancel' | translate }}
                                </button>
                                <button
                                type="submit"
                                class="btn btn-primary my-2"
                                [disabled]="!getFormControl('templateType').value">
                                {{ 'continue' | translate }}
                              </button>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-6 p-4 m-auto d-none d-md-block border-left">
                        <img src="assets/img/infography/creation_form.png" class="img-fluid mx-auto d-block" width="80%" alt="{{ 'seo.app_creation.img_alt.1' | translate }}" />
                        <h2 class="text-center mt-4">{{ 'create_app_form.title' | translate }}</h2>
                        <p>{{ 'create_app_form.description' | translate }}
                            <strong>{{ 'create_app_form.your_app_name_1' | translate }}</strong>
                            {{ 'create_app_form.your_app_name_2' | translate }}
                            <strong>{{ 'create_app_form.your_app_name_3' | translate }}</strong>
                        </p>
                        <ul>
                            <li class="h4 font-weight-light">
                                &nbsp; {{ 'create_app_form.first_description' | translate }}
                            </li>
                            <li class="h4 font-weight-light">
                                &nbsp;  {{ 'create_app_form.second_description' | translate }}
                            </li>
                            <li class="h4 font-weight-light">
                                &nbsp;  {{ 'create_app_form.third_description' | translate }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
